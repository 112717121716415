import { Suspense, lazy, ElementType } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
// hooks
// layouts
import MainLayout from '../layouts/main'

import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
// guards
import AuthGuard from '../guards/AuthGuard'
// components
import LoadingScreen from '../components/LoadingScreen'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const { isAuthenticated } = useAuth()

    const isDashboard = pathname.includes('/dashboard')

    return (
        <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
            <Component {...props} />
        </Suspense>
    )
}

export default function Router() {
    return useRoutes([
        // {
        //     path: '*',
        //     element: <LogoOnlyLayout />,
        //     children: [
        //         { path: '500', element: <Page500 /> },
        //         { path: '404', element: <Page404 /> },
        //         { path: '403', element: <Page403 /> },
        //         { path: '*', element: <Navigate to="/404" replace /> },
        //     ],
        // },
        {
            path: '/',
            element: (
                <AuthGuard>
                    <MainLayout />
                </AuthGuard>
            ),
            children: [
                { element: <HomePage />, index: true },
            ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ])
}

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')))
const Page500 = Loadable(lazy(() => import('../pages/Page500')))
const Page403 = Loadable(lazy(() => import('../pages/Page403')))
const Page404 = Loadable(lazy(() => import('../pages/Page404')))
