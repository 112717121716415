import { Outlet } from 'react-router-dom'
import { Stack } from '@mui/material'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'

// ----------------------------------------------------------------------

export default function MainLayout() {

    return (
        <Stack sx={{ minHeight: 1, bgcolor: 'rgb(248, 251, 251)' }}>
            <Stack direction="column">
                <MainHeader />
                <Outlet />
                <MainFooter />
            </Stack>
            
        </Stack>
    )
}
