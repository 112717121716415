import { useState, ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// components
import LoadingScreen from '../components/LoadingScreen'
import { hooks, metaMask } from '~/connectors/metaMask'
// import { hooks, network } from '~/connectors/network'
const { useIsActivating, useIsActive, useChainId } = hooks
// ----------------------------------------------------------------------

type AuthGuardProps = {
    children: ReactNode;
};

const AuthGuard = ({ children }: AuthGuardProps) => {
    const chainId = useChainId()
    const isActive = useIsActive()
    const isActivating = useIsActivating()
    // const { isAuthenticated, isInitialized } = useAuth()

    const { pathname } = useLocation()
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

    useEffect(() => {
        if (!isActive && chainId !== 137) {
            void metaMask.connectEagerly().catch(() => {
                console.debug('Failed to connect eagerly to metamask')
            })
        }
    }, [])
    if (isActivating) {
        return <LoadingScreen />
    }
    if (!window.ethereum) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname)
        }
    }


    return <>{children}</>
}

export default AuthGuard
