import * as ethers from 'ethers'
import { useState, useEffect } from 'react'
import { getChainNetworkInfo, NetworkType } from '~/config/chains'
import SafeboxFactoryJSON from '~/contracts/v1/SafeboxFactory.json'
import ZKPassJSON from '~/contracts/v1/ZKPass.json'
import ERC721JSON from '~/token/ERC721.json'
import Safebox from '~/contracts/v1/Safebox.json'
import { hooks } from '~/connectors/metaMask'
const { useProvider } = hooks
// const passwordServiceAddr = '0x2CB213127Fa481E7D9303bedB0Bd3FC3461D2a9A'
// const zkPassADDR = '0xCDc902C17985f5d66A857F67a2BD6f5A29cE225d'
// const safeboxFactoryAddr = '0xB53CB1feEbea105C30982e7f2Ed803a2195DA922' // old
// const safeboxFactoryAddr = '0x5a93D9a81F1ee8368BaD0EEb0f653bB45bFc6329'

// const providerURL = 'https://matic-mainnet.chainstacklabs.com'

export type ZKContractsType = {
    deployer: string
    safeboxFactoryAddr: string
    zkpassAddr: string
}


export function useContracts() {
    const { useChainId } = hooks
    const chainId = useChainId()
    const networkInfo = getChainNetworkInfo(chainId)
    const [currentNetwork, setCurrentNetwork] = useState<NetworkType>(networkInfo)

    const provider = useProvider()
    let safeboxFactory
    let zkPass
    let getSafeboxFactory
    let getERC721Factory

    // console.log('useContracts', chainId)

    if (provider) {
        const signer = (provider as any).getSigner()
        safeboxFactory = (new ethers.Contract(networkInfo.zkContract.safeboxFactoryAddr, SafeboxFactoryJSON.abi, signer))
        zkPass = (new ethers.Contract(networkInfo.zkContract.zkpassAddr, ZKPassJSON.abi, signer))
        getSafeboxFactory = (safeboxAddress: string) => (new ethers.Contract(safeboxAddress, Safebox.abi, signer))
        getERC721Factory = (nftAddress: string) => (new ethers.Contract(nftAddress, ERC721JSON.abi, signer))
    }


    useEffect(() => {
        if (provider) {
            // console.log('networkInfo', networkInfo)
            // console.log('contract', networkInfo.zkContract)
            const signer = (provider as any).getSigner()
            safeboxFactory = (new ethers.Contract(networkInfo.zkContract.safeboxFactoryAddr, SafeboxFactoryJSON.abi, signer))
            zkPass = (new ethers.Contract(networkInfo.zkContract.zkpassAddr, ZKPassJSON.abi, signer))
            getSafeboxFactory = (safeboxAddress: string) => (new ethers.Contract(safeboxAddress, Safebox.abi, signer))
            getERC721Factory = (nftAddress: string) => (new ethers.Contract(nftAddress, ERC721JSON.abi, signer))
        }

    }, [currentNetwork])

    useEffect(() => {
        const networkInfo = getChainNetworkInfo(chainId)
        setCurrentNetwork({
            ...networkInfo
        })
    }, [useChainId()])



    return {
        safeboxFactory,
        zkPass,
        getSafeboxFactory,
        getERC721Factory,
        currentNetwork,
        setCurrentNetwork,
    }
}