import { Link as RouterLink } from 'react-router-dom'
// @mui
import { useTheme } from '@mui/material/styles'
import { Box, BoxProps } from '@mui/material'
import logoSvg from '~/assets/zkpass_logo.svg'
import Image from '~/components/Image'
// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
    const theme = useTheme()


    const logo = (
        <Box sx={{ width: 40, height: 40, ...sx }}>
            <Image src={logoSvg} sx={{ pt: 1 }}/>
        </Box>
    )

    if (disabledLink) {
        return <>{logo}</>
    }

    return <RouterLink to="/">{logo}</RouterLink>
}
