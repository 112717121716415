// import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
// @mui
import { styled, useTheme, alpha } from '@mui/material/styles'
import { Stack, Button, AppBar, Toolbar, Container, Menu, Typography, MenuItem } from '@mui/material'
import { MenuProps } from '@mui/material/Menu'
import { ButtonProps } from '@mui/material/Button'
import Image from '~/components/Image'
import MetaMaskIcon from '~/assets/MetaMask.svg'
import { shortAccount } from '~/utils/formatString'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// hooks
import useOffSetTop from '~/hooks/useOffSetTop'
import { hooks, metaMask } from '~/connectors/metaMask'
import { NetworkType, getAddChainParameters, networkInfo, chainIsEnable } from '~/config/chains'
import { useContracts } from '~/hooks/useContracts'
import useResponsive from '~/hooks/useResponsive'
// utils
import cssStyles from '~/utils/cssStyles'
// config
import { HEADER } from '~/config'
// components
// import Logo from '~/components/Logo'
import Logo from '~/assets/zkpass_logo.svg'
// import useSafebox from '~/hooks/useSafebox'

// import Label from '~/components/Label'
//
// import MenuDesktop from './MenuDesktop'
// import MenuMobile from './MenuMobile'
// import navConfig from './MenuConfig'

// ----------------------------------------------------------------------



const { useAccounts, useChainId, useIsActive } = hooks


const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: HEADER.MOBILE_HEIGHT,
    transition: theme.transitions.create(['height', 'background-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
        height: HEADER.MAIN_DESKTOP_HEIGHT,
    },
}))

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    width: 'calc(100% - 48px)',
    boxShadow: theme.customShadows.z8,
}))

const StyleButtonDis = styled((props: ButtonProps) => (
    <Button variant="outlined" {...props} />
))(({ theme }) => ({
    borderColor: '#eee',
    '&.Mui-disabled': {
        borderColor: '#eee',
        color: '#454545'
    },
}))

const StyleButtonSafe = styled((props: ButtonProps) => (
    <Button variant="outlined" {...props} />
))(({ theme }) => ({
    borderColor: '#eee',
    '&.Mui-disabled': {
        borderColor: '#eee'
    }
}))

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}))
// ----------------------------------------------------------------------

export default function MainHeader() {
    // const { safeboxAddr } = useSafebox()
    // const [safeAddr, setSafeAddr] = useState('--')
    const isActive = useIsActive()
    const { currentNetwork, setCurrentNetwork } = useContracts()

    const chainId = useChainId()

    const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT)

    const accounts = useAccounts()

    const theme = useTheme()

    // const [isConnectZKSafe, setConnectZKSafe] = useState(false)

    // const { pathname } = useLocation()


    // const isDesktop = useResponsive('up', 'md')
    const isBigScreen = useResponsive('up', 'xl')
    const isLgScreen = useResponsive('up', 'lg')
    // const isHome = pathname === '/'

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [isOpenMaxHeight, setOpenMaxHeight] = useState<null | HTMLElement>(null)

    const handleMaxHeightClose = () => {
        setOpenMaxHeight(null)
        
    }
    const handleDisconnect = async () => {
        if (metaMask?.deactivate) {
            await metaMask.deactivate()
        } else {
            await metaMask.resetState()
        }
        handleMaxHeightClose()
    }
    const handleClickAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenMaxHeight(event.currentTarget)
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null)
    }

    const selectNetwork = (netWork: NetworkType) => {
        setAnchorEl(null)
        handleSwitchNetwork(netWork.chainId)
        setCurrentNetwork({
            ...netWork
        })
    }


    const handleSwitchNetwork = async (chainId: number | undefined) => {
        try {
            // setLoading(true) 
            await metaMask.activate(getAddChainParameters(chainId || 137))
            setTimeout(async () => {
                await metaMask.connectEagerly().catch(() => {
                    console.debug('Failed to connect eagerly to metamask')
                })
            }, 500)
            // console.log(res)
            // setLoading(false)
        } catch(err) {
            console.error(err)
            // setLoading(false)
        }
    }


    return (
        <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent', position: 'inherit' }}>
            <ToolbarStyle
                disableGutters
                sx={{
                    ...(isOffset && {
                        ...cssStyles(theme).bgBlur(),
                        height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
                    }),
                }}
            >
                <Container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    maxWidth={'lg'}
                >
                    {/* <Logo /> */}
                    <Image src={Logo} sx={{ width: 360 }}/>
                    {/* <Link
                        href="https://docs-minimals.vercel.app/changelog"
                        target="_blank"
                        rel="noopener"
                        underline="none"
                    >
                        <Label color="info" sx={{ ml: 1 }}>
                            v1.0.0
                        </Label>
                    </Link> */}

                    {/* <Box sx={{ flexGrow: 1 }} /> */}

                    {/* {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}

                    <Stack direction={'row'} justifyContent="flex-end" alignItems={'center'} spacing={3}>
                        {accounts && (!chainIsEnable(chainId)) && <Button variant="outlined" color="warning" onClick={e => handleSwitchNetwork(chainId)}>Switch Network</Button>}
                        {chainId && (chainIsEnable(chainId)) && <>
                            <StyleButtonSafe
                                color="success" 
                                id="demo-customized-button"
                                aria-controls={open ? 'customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="outlined"
                                disableElevation
                                onClick={handleClick}
                                endIcon={<KeyboardArrowDownIcon sx={{ color: '#454545' }}/>}
                            >
                                <Stack direction={'row'} spacing={1} alignItems="center" >
                                    <Image src={currentNetwork.icon} sx={{ width:20 }} />
                                    <Typography variant='body1' sx={{ color: '#454545' }}>{currentNetwork.title}</Typography>
                                </Stack>
                            </StyleButtonSafe>
                            <StyledMenu
                                id="selector-network-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'selector-network-menu',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                {networkInfo.map((v: NetworkType) => <MenuItem disabled={!v.enable} key={v.title} onClick={e => selectNetwork(v)} disableRipple>
                                    <Stack direction={'row'} spacing={1} alignItems="center">
                                        <Image src={v.icon} sx={{ width:24 }} />
                                        <Typography variant='body1'>{v.title}</Typography>
                                    </Stack>
                                </MenuItem>)}
                            </StyledMenu>
                        </>}
                        
                        {accounts ? <StyleButtonSafe variant="outlined"  color="success" onClick={handleClickAccount} sx={{ color: '#454545' }}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Image src={MetaMaskIcon} sx={{ width:22 }} />
                                <Typography variant='body1'>{shortAccount(accounts ? accounts[0]: '')}</Typography>
                            </Stack>
                        </StyleButtonSafe> : <StyleButtonSafe variant="outlined" color="success" onClick={e => handleSwitchNetwork(chainId)} >
                            <Typography variant='body1'>Connect Wallet</Typography>
                        </StyleButtonSafe>}
                        <Menu
                            keepMounted
                            id="long-menu"
                            anchorEl={isOpenMaxHeight}
                            onClose={handleMaxHeightClose}
                            open={Boolean(isOpenMaxHeight)}
                            // PaperProps={{
                            //     style: {
                            //         maxHeight: 48 * 4.5,
                            //         width: '20ch',
                            //     },
                            // }}
                        >
                            <MenuItem
                                // selected={option === 'Pyxis'}
                                onClick={handleDisconnect}
                            >
                                Disconnect
                            </MenuItem>
                        </Menu>
                    </Stack>

                    {/* {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
                </Container>
            </ToolbarStyle>

            {/* {isOffset && <ToolbarShadowStyle />} */}
        </AppBar>
    )
}
