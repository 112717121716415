// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import ThemeSettings from './components/settings'
// import { ChartStyle } from './components/chart'
import ScrollToTop from './components/ScrollToTop'
import { ProgressBarStyle } from './components/ProgressBar'
import NotistackProvider from './components/NotistackProvider'
import MotionLazyContainer from './components/animate/MotionLazyContainer'
import Web3Provider from '~/components/Web3Provider'

// ----------------------------------------------------------------------

export default function App() {
    return (
        <Web3Provider>
            <MotionLazyContainer>
                <ThemeProvider>
                    <ThemeSettings>
                        <NotistackProvider>
                            <ProgressBarStyle />
                            <ScrollToTop />
                            <Router />
                        </NotistackProvider>
                    </ThemeSettings>
                </ThemeProvider>
            </MotionLazyContainer>
        </Web3Provider>
    )
}
