import type { AddEthereumChainParameter } from '@web3-react/types'
import { ZKContractsType } from '~/hooks/useContracts'
import EthIcon from '~/assets/Ethereum.svg'
import PolygonIcon from '~/assets/Polygon.svg'
import OptimismIcon from '~/assets/Optimism.svg'
import ArbitrumsvgIcon from '~/assets/Arbitrumsvg.svg'
import BSCIcon from '~/assets/BSC.svg'

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
}

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18,
}

// const CELO: AddEthereumChainParameter['nativeCurrency'] = {
//     name: 'Celo',
//     symbol: 'CELO',
//     decimals: 18,
// }

const BNB: AddEthereumChainParameter['nativeCurrency'] = {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
}

interface BasicChainInformation {
    urls: string[]
    name: string
}

interface ExtendedChainInformation extends BasicChainInformation {
    nativeCurrency: AddEthereumChainParameter['nativeCurrency']
    blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

export const CHAINS: { [chainId: number]: BasicChainInformation | ExtendedChainInformation } = {
    1: {
        urls: [
            process.env.infuraKey ? `https://mainnet.infura.io/v3/${process.env.infuraKey}` : '',
            process.env.alchemyKey ? `https://eth-mainnet.alchemyapi.io/v2/${process.env.alchemyKey}` : '',
            'https://cloudflare-eth.com',
        ].filter((url) => url !== ''),
        name: 'Mainnet',
    },
    // Polygon
    137: {
        urls: [
            process.env.infuraKey ? `https://polygon-mainnet.infura.io/v3/${process.env.infuraKey}` : '',
            'https://matic-mainnet.chainstacklabs.com',
        ].filter((url) => url !== ''),
        name: 'Polygon Mainnet',
        nativeCurrency: MATIC,
        blockExplorerUrls: ['https://polygonscan.com'],
    },
    // BNB
    56: {
        urls: [
            'https://bsc-dataseed4.defibit.io/',
        ].filter((url) => url !== ''),
        name: 'BNB Chain',
        nativeCurrency: BNB,
        blockExplorerUrls: ['https://bscscan.com'],
    },
    // Optimism
    10: {
        urls: ['https://mainnet.optimism.io',
        ].filter((url) => url !== ''),
        name: 'Optimism',
        nativeCurrency: ETH,
        blockExplorerUrls: ['https://optimistic.etherscan.io'],
    },
    // Arbitrum
    42161: {
        urls: [
            'https://arb1.arbitrum.io/rpc',
        ].filter((url) => url !== ''),
        name: 'Arbitrum One',
        nativeCurrency: ETH,
        blockExplorerUrls: ['https://arbiscan.io'],
    }
}

function isExtendedChainInformation(
    chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
    return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export function getAddChainParameters(chainId: number): AddEthereumChainParameter | number {
    const chainInformation = CHAINS[chainId]
    if (isExtendedChainInformation(chainInformation)) {
        return {
            chainId,
            chainName: chainInformation.name,
            nativeCurrency: chainInformation.nativeCurrency,
            rpcUrls: chainInformation.urls,
            blockExplorerUrls: chainInformation.blockExplorerUrls,
        }
    } else {
        return chainId
    }
}

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
    (accumulator, chainId) => {
        const validURLs: string[] = CHAINS[Number(chainId)].urls

        if (validURLs.length) {
            accumulator[Number(chainId)] = validURLs
        }

        return accumulator
    },
    {}
)

export type NetworkType = {
    chainId: number | undefined
    title: string
    icon: string
    enable: boolean
    explorerUrl: string
    zkContract: ZKContractsType
    limitEventNumber: number
}

export const networkInfo: NetworkType[] = [
    {
        chainId: 1,
        title: 'Ethereum',
        icon: EthIcon,
        enable: true,
        explorerUrl: 'https://etherscan.io',
        zkContract: {
            deployer: '0x45914F46006c3e6fD7d68064370fB6e1f5564550',
            safeboxFactoryAddr: '0x8528d5a340Bef2e50844CDABdFa21bC6B57c3982',
            zkpassAddr: '0x9802cBf6480FE2a0c69740Bc8008739DfF1E7CEF'
        },
        limitEventNumber: 3000
    },
    {
        chainId: 137,
        title: 'Polygon',
        icon: PolygonIcon,
        enable: true,
        explorerUrl: 'https://polygonscan.com',
        zkContract: {
            deployer: '0x45914F46006c3e6fD7d68064370fB6e1f5564550',
            safeboxFactoryAddr: '0x8528d5a340Bef2e50844CDABdFa21bC6B57c3982',
            zkpassAddr: '0x9802cBf6480FE2a0c69740Bc8008739DfF1E7CEF'
        },
        limitEventNumber: 10000
    },
    {
        chainId: 10,
        title: 'Optimism',
        icon: OptimismIcon,
        enable: true,
        explorerUrl: 'https://optimistic.etherscan.io',
        zkContract: {
            deployer: '0x45914F46006c3e6fD7d68064370fB6e1f5564550',
            safeboxFactoryAddr: '0x8528d5a340Bef2e50844CDABdFa21bC6B57c3982',
            zkpassAddr: '0x9802cBf6480FE2a0c69740Bc8008739DfF1E7CEF'
        },
        limitEventNumber: 10000
    },
    {
        chainId: 42161,
        title: 'Arbitrum ',
        icon: ArbitrumsvgIcon,
        enable: true,
        explorerUrl: 'https://arbiscan.io',
        zkContract: {
            deployer: '0x45914F46006c3e6fD7d68064370fB6e1f5564550',
            safeboxFactoryAddr: '0x8528d5a340Bef2e50844CDABdFa21bC6B57c3982',
            zkpassAddr: '0x9802cBf6480FE2a0c69740Bc8008739DfF1E7CEF'
        },
        limitEventNumber: 10000
    },
    {
        chainId: 56,
        title: 'BSC',
        icon: BSCIcon,
        enable: true,
        explorerUrl: 'https://bscscan.com',
        zkContract: {
            deployer: '0x45914F46006c3e6fD7d68064370fB6e1f5564550',
            safeboxFactoryAddr: '0x8528d5a340Bef2e50844CDABdFa21bC6B57c3982',
            zkpassAddr: '0x9802cBf6480FE2a0c69740Bc8008739DfF1E7CEF'
        },
        limitEventNumber: 3000
    }
]

export const defaultNetwork: NetworkType = {
    chainId: 137,
    title: 'Polygon',
    icon: PolygonIcon,
    enable: true,
    explorerUrl: 'https://polygonscan.com',
    zkContract: {
        deployer: '0x45914F46006c3e6fD7d68064370fB6e1f5564550',
        safeboxFactoryAddr: '0x8528d5a340Bef2e50844CDABdFa21bC6B57c3982',
        zkpassAddr: '0x9802cBf6480FE2a0c69740Bc8008739DfF1E7CEF'
    },
    limitEventNumber: 10000
}


export const chainIsEnable = (chainId: number | undefined) => {
    const netWork = networkInfo.find(network => network.chainId === chainId && network.enable)
    if (netWork) {
        return true
    }
    return false
}

export function getChainNetworkInfo(chainId: number | undefined): NetworkType {
    return networkInfo.find(network => network.chainId === chainId) || defaultNetwork
}

export function getChainContracts(chainId: number | undefined): ZKContractsType {
    const netWork = networkInfo.find(network => network.chainId === chainId)
    if (netWork) {
        return netWork.zkContract
    } else {
        return defaultNetwork.zkContract
    }
}
