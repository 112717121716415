export function shortAccount(account?: string) {
    const startCut = 5
    const cut = 4

    if (account === undefined) {
        return '--'
    }

    if (account.length > 16) {
        return `${account.slice(0, startCut)}...${account.slice(-cut)}`
    } else {
        return account
    }
}

export function shortTxn(txn?: string) {
    const cut = 20

    if (txn === undefined) {
        return '--'
    }

    if (txn.length > 20) {
        return `${txn.slice(0, cut)}...`
    } else {
        return txn
    }
}

export function isJsonString(str: string | undefined) {
    try {
        if (str) {
            JSON.parse(str)
        } else {
            return false
        }
    } catch (e) {
        return false
    }
    return true
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}
