import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material'
// routes
import { PATH_PAGE } from '../../routes/paths'


// ----------------------------------------------------------------------

const LINKS = [
    {
        headline: 'Minimal',
        children: [
            { name: 'About us', href: PATH_PAGE.about },
            // { name: 'Contact us', href: PATH_PAGE.contact },
            // { name: 'FAQs', href: PATH_PAGE.faqs },
        ],
    },
    {
        headline: 'Legal',
        children: [
            { name: 'Terms and Condition', href: '#' },
            // { name: 'Privacy Policy', href: '#' },
        ],
    },
    {
        headline: 'Contact',
        children: [
            { name: 'support@minimals.cc', href: '#' },
            // { name: 'Los Angeles, 359  Hidden Valley Road', href: '#' },
        ],
    },
]

const RootStyle = styled('div')(({ theme }) => ({
    position: 'relative',
    zIndex: 0,
    width: '100vw',
    bgcolor: 'rgb(248, 251, 251)' ,
    backgroundColor: theme.palette.background.default,
}))

// ----------------------------------------------------------------------

export default function MainFooter() {
    return (
        <RootStyle sx={{ bgcolor: 'rgb(248, 251, 251)' }}>
            {/* <Divider /> */}

            <Container sx={{ py: 2, bgcolor: 'rgb(248, 251, 251)'  }} maxWidth={'lg'}>
                <Grid
                    container
                    justifyContent={{ xs: 'center', md: 'space-between' }}
                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                >
                    <Grid item xs={4} >
                        {/* <Link
                            href="https://zksafe.pro"
                            // key={link.name}
                            color="inherit"
                            variant="body2"
                            target={'_blank'}
                            // component={RouterLink}
                            sx={{ display: 'block', color: '#999' }}
                        >
                                zksafe.pro
                        </Link> */}
                    </Grid>
                    <Grid item xs={4}>
                        <Stack
                            spacing={2}
                            direction={{ xs: 'column', md: 'row' }}
                            justifyContent="center"
                        >
                            <Link
                                href="https://zksafe.pro"
                                // key={link.name}
                                color="inherit"
                                variant="body2"
                                target={'_blank'}
                                // component={RouterLink}
                                sx={{ display: 'block', color: '#999' }}
                            >
                                zksafe.pro
                            </Link>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Link
                                href="https://zksafe.gitbook.io/readme/eps/eps"
                                // key={link.name}
                                target={'_blank'}
                                color="inherit"
                                variant="body2"
                                // component={RouterLink}
                                sx={{ display: 'block', color: '#999' }}
                            >
                                docs 
                            </Link>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Link
                                href="https://twitter.com/ZKSAFE"
                                // key={link.name}
                                color="inherit"
                                variant="body2"
                                target={'_blank'}
                                // component={RouterLink}
                                sx={{ display: 'block', color: '#999' }}
                            >
                                twitter
                            </Link>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Link
                                href="https://discord.gg/7DxGbVP3Tf"
                                // key={link.name}
                                color="inherit"
                                variant="body2"
                                target={'_blank'}
                                // component={RouterLink}
                                sx={{ display: 'block', color: '#999' }}
                            >
                                discord 
                            </Link>
                            
                        </Stack>
                    </Grid>
                    <Grid item xs={4} 
                    >
                        <Stack
                            direction="row"
                            justifyContent="right"
                        >
                            <Link
                                href="https://www.zksafe.pro/audit/SlowMistAuditReport-ZKSAFE.pdf"
                                // key={link.name}
                                color="inherit"
                                variant="body2"
                                target={'_blank'}
                                // component={RouterLink}
                                sx={{ display: 'block', color: '#999' }}
                            >
                                SlowMist Audit Report
                            </Link>
                        </Stack>
                        
                    </Grid>
                </Grid>

                {/* <Typography
                    component="p"
                    variant="body2"
                    sx={{
                        mt: 10,
                        pb: 5,
                        fontSize: 13,
                        textAlign: { xs: 'center', md: 'left' },
                    }}
                >
          © 2021. All rights reserved
                </Typography> */}
            </Container>
        </RootStyle>
    )
}
